





























































































import { Component, PropSync, Vue } from 'vue-property-decorator';

import StepDistributeBalanceWealthViewModel
  from '@/vue-app/view-models/components/flagship/flagship-link-goals/steps-wealth/step-distribute-balance-wealth-view-model';

@Component({ name: 'StepDistributeBalanceWealth' })
export default class StepDistributeBalanceWealth extends Vue {
  @PropSync('associatedProductId', { type: String, required: true })
  associated_product_id!: string;

  @PropSync('goalsSelected', { type: Array, required: true })
  goals_selected!: Array<string>;

  @PropSync('investorGoals', { type: Object, required: true })
  investor_goal!: Record<string, any>;

  @PropSync('isLoading', { type: Boolean, required: true, default: false })
  is_loading!: boolean;

  step_distribute_balance_wealth_model = Vue.observable(
    new StepDistributeBalanceWealthViewModel(this),
  );

  created() {
    this.step_distribute_balance_wealth_model.initialize(
      this.associated_product_id, this.goals_selected, this.investor_goal,
    );
  }
}
